@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Volkhov:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap');

*,*::after,*::before{
    box-sizing: border-box;
}

body{
  font-family: 'Poppins', sans-serif;
  font-weight:500; 
  margin:0;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #ababab #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ababab;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

.navbar--logo {
  font-family: 'Volkhov', serif;
  color: #222;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.009rem;
  padding-left: 0;
}

header {
width:100%;
background: #fff;
position: relative;
z-index: 999;
text-align: center;

}

nav{
position:absolute;
text-align:left;
top:100%;
background: #FD8D3A;
width:100%;
transform: scale(1, 0);
transform-origin: top;
transition: transform 400ms ease-in-out;
}

nav ul {
  
list-style: none;
margin:0;
padding:0;  
}

nav li {
margin-left: 1em;
margin-bottom: 1em;
}

li a{
font-family: 'Poppins', serif;
color: black;
}

a:hover {
color: #FA7436;
cursor:pointer;
}

.nav-toggle{
display:none;
}

.nav-toggle:checked ~ nav{  
transform: scale(1,1);

}
.nav-toggle:checked ~ nav a{
opacity:1;
transition: all .1s ease-in-out .200s ; 
}

nav a {
opacity:0;
transform: scale(1,0);
transition: opacity 150ms ease-in-out;
}

.nav-toggle-label{
position:absolute;
top:0;
left:0;
margin-left: 1em;
height:180%;
display:flex;
place-items:center;  
}

.label-span, 
.label-span::before,
.label-span::after {
height:4px;
width:30px;
border:1px solid black;
background:black;
border-radius:20%;
}
.label-span::before,
.label-span::after{
position:absolute;
content: '';
left: 0px;
}
.label-span::before{
top: 30px;  
}
.label-span::after{
bottom: 30px;
}
.label-span:hover,
.label-span:hover::after,
.label-span:hover::before{
cursor:pointer;
border:1px solid var(--clr-title);
background:#FA7436;  
}

@media screen and (min-width:800px) {
  .nav-toggle-label{
    display:none;
  }
  
  header{
    display:grid;
    grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;
    
  }
  nav{
    all:unset;
    grid-column: 4/4;
    display: flex;
    justify-content: flex-end;
    place-items:center;    
  }
  .navbar--logo{
    grid-column: 3/4;
  }
  nav li{
    margin-left: 3em;
    margin-bottom: 0;
  }
  nav ul {
    display: flex;
    justify-content: flex-end;
  }
  nav a {
    opacity:1;
    position:relative;
  }
  nav a::before {
    position:absolute;
    content:'';
    height:4px;
    width:100%;    
    top:-10px;
    right:0;
    background:black;    
    transform:scale(0,1);
    transition: transform ease-in-out 250ms;
  }
  nav a:hover::before {
    transform:scale(1,1);
  }
}

.hero {
  display: absolute;
  align-items: center;
  padding-bottom: 2rem;

}


.logof {
  display: absolute;
  align-items: left;
  padding-bottom: ;

}

.logof--image {
  float: center;
  max-width: 50%; 

}


.hero--image {
  float: right;
  max-width: 50%; 
}

.hero h1, .hero h2 {
  text-align: left;

}

.hero h1 {
  padding-top: 5rem;
  font-family: Volkhov;
  color: #222;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.5rem;
  padding-left: 4rem;
  
}

.hero > h1 > span {
  color: #FA7436;
}

.hero h2 {
  font-size: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  padding-left: 4rem;
}

.btn {
  border: 1px solid #FA7436;
  border-radius: 0.3rem;
  color: #FA7436;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  line-height: 2rem;
  font-weight: 500;
  padding: 13px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 2rem;
  margin-left: 4rem;
  cursor: pointer;
}

.hero--image {
  width: 100%;
  height: auto;
}

@media (max-width: 576px) {
  .hero--image {
    width: 100%;
    height: auto;
  }

  .hero h1 {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .hero h2 {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
  .btn {
    margin-top: 0;
    padding: 10px 10px;
    width: 8rem;
    height: 3rem;
    font-size: 0.9rem;
    line-height: 1rem;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .hero--image {
    width: 80%;
    height: auto;

}

.hero h1 {
  font-size: 2rem;
  line-height: 2.25rem;
}

.hero h2 {
  font-size: 0.9rem;
  line-height: 1.5rem;
}
.btn {
  margin-top: 0;
  padding: 10px 10px;
  width: 8rem;
  height: 3rem;
  font-size: 0.9rem;
  line-height: 1rem;
}
}

@media (min-width: 769px) and (max-width: 992px) {
  .hero--image {
    width: 80%;
    height: auto;
  }
}

@media (min-width: 993px) {
  .hero--image {
    width: 80%;
    height: auto;
  }
}

@media (min-width: 1200px) and (max-width: 1400px){
  .hero--image {
    max-width: 45%; 
  }

}

@media (min-width: 1401px) and (max-width: 2000px){
  .hero--image {
    max-width: 30%; 
  }

}

@media (min-width: 2000px) and (max-width: 2700px){
  .hero--image {
    max-width: 25%; 
  }

}

.cards-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 50px;
  overflow-x: auto;
  justify-content: center;
}

.card {
  position: relative;
  font-size: 1rem;
  flex: 0 0 auto;         
  display: flex;         
  flex-direction: column;  
  width: 16rem;
  height: 23.650rem;
  border-radius: 0.625rem;
  background: #FEFCFB;
  box-shadow: 0px 16px 80px 0px rgba(254, 147, 94, 0.20);
  margin-bottom: 20px;
  
}

.card-button {
  display: none;
  border-radius: 0.5rem;
  background: var(--primary-o, #FA7436);
  padding: 0.5625rem 1.3125rem 0.5625rem 1.375rem;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  font-family: 'Poppins', sans-serif;
  box-shadow: none;
  border: none;
  text-decoration: none;
}

.card:hover .card-button {
  transition: .3s all;
  display: block;
  position: absolute;
  top: 30%;
  left: 25%;
  cursor: pointer;
  z-index: 1;
}

.card {
  transition: filter 0.3s;
}

.card:hover .card--image {
  transition: .9s all;
  transition-duration: 1.5s;
  filter: grayscale(100%);
}

.card--image {
  width: 15.975rem;
  height: 17.125rem;
  object-fit: cover;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.40);
  margin-bottom: 9px;
}

.card--container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  margin: 10px; 
}

.card--star {
  height: 14px;
  margin-right: 0.2rem;
}

.card--title {
  color: var(--primary-b, #222);
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;

}

.card--rating {
  color: var(--paragraph, #666);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;

}

.card--location--pricing {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  margin: 10px; 
}

.img--card--icon {
  width: 0.78569rem;
  height: 1rem;
  flex-shrink: 0;
  margin-right: 5px;
}

.card--location {
  color: var(--paragraph, #666);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-right: auto;

}

.card--old--pricing {
  color: var(--paragraph, #666);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  text-decoration: line-through;
  margin-right: 1rem;
}

.card--new--pricing {
  color: var(--primary-o, #FA7436);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  border-radius: 0.25rem;
  background: #FFE7DB;
}

.card--badge {
  position: absolute;
  top: 6px;
  left: 6px;
  background-color: white;
  padding: 5px 7px;
  border-radius: 4px;
  font-weight: bold;
}

.centered-text {
  margin-top: 5rem;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.centered-text > h1 {
  color: #222;
  font-family: Volkhov;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.375rem;
  margin-bottom: 1rem;
}

.centered-text > h1 > span {
  color: #FA7436;
}

.centered-text > p {
  width: 27rem;
  color: var(--paragraph, #666);
  text-align: center;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  padding-bottom: 3rem;
}
.vacation--plans {
  padding-top: 3rem;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #F7F8FC;
  z-index: 1;

}

.container {
  display: flex;
  align-items: center;
  align-text: center;
}

.container-title-img {
  width: 11.24356rem;
  height: 8.17075rem;
  flex-shrink: 0;
  z-index: 0;
}

.container-title > h1 {
  color: #222;
  font-family: Volkhov;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.375rem;
  margin-bottom: 1rem;
  margin-right: 10px;
  margin-left: 10rem;
}

.container-title > h1 > span {
  color: #FA7436;
}

.container-title > p {
  width: 27.550rem;
  color: var(--paragraph, #666);
  text-align: center;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  padding-bottom: 3rem;
  margin-left: 13rem;
}

.vacation--plans--li {
  align-items: center;
  justify-content: center;
  grid-gap: 10%; 
  display: grid;
  grid-template-columns: 350px 350px 350px;
  width: 23.125rem;
  height: 17.375rem;
  flex-shrink: 0;
  width: 350px;
  height: 300px;
  margin: 10px;
}

.vacation--plans--li--img {
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  margin-right: 14rem;
  margin-top: 2rem;
  
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.5rem;
  border: 2px solid rgba(102, 102, 102, 0.08);
  background: #FFF;
}

.column > h1 {
  color: var(--primary-b, #222);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  display: flex;
  width: 20rem;
  flex-direction: column;
  flex-shrink: 0;
  text-align: left;
  padding-left: 1.5rem;
  margin-top: 3rem;
}

.column > p {
  color: var(--paragraph, #666);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  display: flex;
  width: 20rem;
  flex-direction: column;
  flex-shrink: 0;
  text-align: left;
  padding-left: 1.5rem;
  margin-bottom: 2rem;
}

.tripcard {
  display: none;
  position: relative;
  font-size: 1rem;
  flex: 0 0 auto;         
  display: flex;         
  flex-direction: column;  
  width: 22.400rem;
  height: 25rem;
  border-radius: 0.625rem;
  background: #FEFCFB;
  box-shadow: 0px 16px 80px 0px rgba(254, 147, 94, 0.20);
  justify-content: center;
  margin-bottom: 20px;
  
}

.tripcard:hover .card-button {
  transition: .3s all;
  display: none;
  position: absolute;
  top: 32%;
  left: 32%;
  cursor: pointer;
  z-index: 1;
}

.tripcard {
  transition: filter 0.3s;
  display: none;
}

.tripcard:hover .tripcard--image {
  display: none;
  transition: .9s all;
  transition-duration: 1.5s;
  filter: grayscale(100%);
}

.tripcard--image {
  display: none;
  width: 22.400rem;
  height: 18rem;
  object-fit: cover;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.40);
  margin-bottom: 9px;
}


.tripcard--container {
  display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  margin: 10px; 
  line-height: 1.5rem;
}


.card--location--city{
  color: var(--primary-b, #222);
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.card--location--country {
  color: var(--primary-b, #222);
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 0.2rem;

}

.card--price {
    color: var(--primary-o, #FA7436);
    font-family: Poppins;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    text-align: center;
    margin-left: auto;
  }

  .card--location--info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    margin: 10px; 
  }


  .img--tripcard--icon {
    width: 1.5rem;
    height: 1rem;
    flex-shrink: 0;
    margin-right: 5px;
  }

.card--location {
  color: var(--paragraph, #666);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  margin-right: auto;
}

.updatecard {
  margin-top: 4rem;
  position: relative;
  font-size: 1rem;
  flex: 0 0 auto;         
  display: flex;         
  flex-direction: column;  
  width: 16rem;
  height: 23.650rem;
  border-radius: 0.625rem;
  background: #FEFCFB;
  box-shadow: 0px 16px 80px 0px rgba(254, 147, 94, 0.20);
  margin-bottom: 20px;
  overflow: hidden;
  
}

.updatecard--image {
  width: 15.975rem;
  height: 17.125rem;
  object-fit: cover;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.40);
  margin-bottom: 9px;
}


.updatecard--container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  margin: 10px; 
  line-height: 1.5rem;
}


.updatecard--title{
  color: var(--primary-b, #222);
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  text-align: left;

}
  .updatecard--body{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    margin: 10px; 
  }

.updatecard--date {
  color: var(--paragraph, #666);
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  margin-right: auto;
}

.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 30px 20px 30px;
  background: #F7F8FC;
  
}

.footer > * {
  flex:  1 100%;
}

.footer__addr {
  margin-right: 30em;
  margin-bottom: 2em;
}

.footer__logo {
  font-family: 'Volkhov', serif;
  color: #222;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.009rem;
  padding-left: 0;
  display: inline-block;
  margin-right: 10px;
  margin-left: 1rem;
}

.footer__logo__img {
  display: inline-block;
}

.nav__title {
  color: var(--primary-b, #222);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem;
}

.footer address {
  color: #666;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; 
  width: 270px;
  margin-left: 1rem;
}

.footer__socials__img {
  width: 70px;
}

.footer__socials__img:hover {
  cursor: pointer;
}

.footer__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: rgb(33, 33, 33, 0.07);
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1.4rem;
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
  
}

.footer li {
  line-height: 2em;
  
}

.footer a {
  text-decoration: none;
  
}

.footer__nav {
  display: flex;
	flex-flow: row wrap;
}

.footer__nav > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul a {
  color: var(--paragraph, #666);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem; 
  
}

.line {
  border-bottom: 1px solid  rgba(153, 153, 153, 0.56);
  padding-top: 1.25rem;

}

.legal {
  
  display: flex;
  flex-wrap: wrap;
  color: #999;
  color: var(--paragraph, #666);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
  
.legal__links {
  display: flex;
  align-items: center;
}

.heart {
  color: #2f2f2f;
}

@media screen and (min-width: 24.375em) {
  .legal .legal__links {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav > * {
    flex: 1;
  }
  
  .footer__addr {
    flex: 1 0px;
  }
  
  .footer__nav {
    flex: 2 0px;
  }
}

.newsletter-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 80vh;
}

.newsletter-content {
  display: flex;
  flex-direction: column; 
  align-items: center;
  align-text: center;
  justify-content: flex-start;
}

.bg {
  border-radius: 1rem;
  background: var(--primary-o, #FA7436);
  width: 73.125rem;
  height: 26rem;
  flex-shrink: 0;
}

.newsletter-title > p {
  color: #FFF;
  text-align: center;
  font-family: Volkhov;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 44.25rem;
  margin-top: 5rem;
}

.form-container {
  position: relative;
  width: 500px;
}

.input-wrapper {
  position: relative;
}


.custom-input {
  color: var(--color-off, #999);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 0.625rem;
  background: var(--white, #FFF);
  width: 32rem;
  height: 4.25rem;
  flex-shrink: 0;
  padding-right: 60px;
  padding-left: 20px;
  border: none; 
  outline: none;
}

.custom-button {
  position: absolute;
  top: 0.55rem;
  right: 0;
  width: 8.25rem;
  height: 3.125rem;
  border-radius: 0.5rem;
  background: var(--primary-o, #FA7436);
  color: #fff;
  border: none;
  cursor: pointer;
  color: #FFF;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
  padding: 0.8125rem 1.625rem;
  margin-bottom: 50rem;
}
